dl.inline dd {
    display: inline;
    margin: 0;
}

dl.inline dd:after {
    display: block;
    content: '';
}

dl.inline dt {
    display: inline-block;
    min-width: 170px;
}

dl.inline {
    margin-bottom: 0;
}