.fullscreen-height {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}

.btn {
  --bs-btn-bg: #00b5f0;
  --bs-btn-hover-bg: #00bfff;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
}

.btn-warning {
  --bs-btn-bg: #ffc107;
  --bs-btn-hover-bg: #ffca2c;
}

.btn-danger {
  --bs-btn-bg: #dc3545;
  --bs-btn-hover-bg: #bb2d3b;
}

.main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: 4px;
  overflow: hidden;
}

.interactive-navbar {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
}

.interactive-navbar .nav-link {
  margin-left: 8px;
}

.navbar-text {
  margin-right: 10px;
}