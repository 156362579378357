@font-face {
  font-family: Manrope;
  src: url(./fonts/Manrope-VariableFont_wght.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-dark-rgb: 35, 54, 82;
}

.accordion {
  --bs-accordion-active-color: #0094a5;
  --bs-accordion-active-bg: #cdf2f7;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 148, 165, 0.25);
}

.nav-link {
  --bs-nav-link-color: #0d91fd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}